import React from 'react';
import Services from './services';
import ContactForm from './contact';
import Header from './header';

export default function Test() {
  return (
    <>
      <Header />
      <Services />
      <ContactForm />
    </>
  );
}
