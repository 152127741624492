import * as THREE from 'three';
import React, { useRef, useMemo, Suspense } from 'react';
import { PerspectiveCamera, Plane, shaderMaterial, useAspect } from 'drei';
import { Canvas, useFrame, extend, createPortal } from 'react-three-fiber';

import frag from '../assets/frag.glsl';
import vert from '../assets/vert.glsl';

extend({ SphereExampleMaterial: shaderMaterial({ resolution: [0, 0], time: 0, text: null }, vert, frag) });

function useRenderTargetTexture() {
  const camera = useRef();

  const [scene, target] = useMemo(() => {
    const s = new THREE.Scene();
    s.background = new THREE.Color('#000');
    const t = new THREE.WebGLMultisampleRenderTarget(256, 256, {
      format: THREE.RGBFormat,
      stencilBuffer: false,
    });
    return [s, t];
  }, []);

  target.samples = 1;

  useFrame((state) => {
    state.gl.setRenderTarget(target);

    state.gl.render(scene, camera.current);
    state.gl.setRenderTarget(null);
  });

  return { camera, scene, texture: target.texture };
}

function Scene() {
  const mat = useRef();

  useFrame(({ clock }) => {
    mat.current.uniforms.time.value = clock.getElapsedTime() / 6;
  });

  const w = window.innerWidth > 512 ? 512 : window.innerWidth;
  const h = window.innerHeight > 512 ? 512 : window.innerHeight;

  const scale = useAspect('cover', w, h, 1);

  const { texture, scene, camera } = useRenderTargetTexture();

  return (
    <>
      {createPortal(
        <>
          {/* <Text
            maxWidth={10}
            font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
            fontSize={1}
            color="white"
          >
            React three Fiber
          </Text> */}
          <PerspectiveCamera ref={camera} position={[0, 0, 4]} />
        </>,
        scene
      )}
      <Plane scale={[...scale, 1]}>
        <sphereExampleMaterial ref={mat} text={texture} resolution={[w, h]} />
      </Plane>
    </>
  );
}

export default function CubeExample() {
  return (
    <Canvas
      shadowMap
      colorManagement
      camera={{ position: [0, 0, 2], far: 50 }}
      style={{
        background: 'black',
      }}
      concurrent
    >
      <Suspense fallback={null}>
        <Scene />
      </Suspense>
      {/* <Stats /> */}
    </Canvas>
  );
}
