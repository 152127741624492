import React from 'react';

import github from '../assets/github.svg';
import twitter from '../assets/twitter.svg';

export default function Footer() {
  return (
    <>
      <footer>
        <div className="contained flex">
          <div className="copyright">
            <b>© 2020 Reflow Labs Pte Ltd.</b>
            <br />
            160 Robinson Road 24-09 Singapore 068914
          </div>
          <div className="social">
            {[
              {
                type: 'github',
                link: 'https://github.com/reflowlabs',
                icon: github,
              },
              {
                type: 'twitter',
                link: 'https://twitter.com/reflow__',
                icon: twitter,
              },
              // {
              //   type: 'email',
              //   link: 'mailto:hello@reflow.dev',
              //   icon: email,
              // },
            ].map(({ type, link, icon }) => (
              <a key={type} href={link} target="_blank">
                <img src={icon} alt={type} />
              </a>
            ))}
          </div>
        </div>
      </footer>
    </>
  );
}
