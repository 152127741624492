import React from 'react';

import Vis from './vis';

export default function Header() {
  return (
    <>
      <div className="header">
        {/* <Vis /> */}
        <h1 className="logo">
          <div>
            <span className="reflow">ReFlow</span>
            <br />
            <span className="labs">Laboratories</span>
          </div>
        </h1>
      </div>
      <div className="quote">
        <p className="large">{'Fluent software for a borderless future.'}</p>
        <p className="small">
          {"We're a cross-discipline devleopment studio that delivers watershed digital experiences."}
        </p>
      </div>
    </>
  );
}
