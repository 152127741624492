import React from 'react';

import Layout from '../components/layout';
import Test from '../components/test';

export default function Home() {
  return (
    <Layout>
      <Test />
    </Layout>
  );
}
