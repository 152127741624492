import React from 'react';
import Helmet from 'react-helmet';

import 'normalize.css';
import 'typeface-source-sans-pro';

import '../assets/style.scss';

import Footer from './footer';

export default function Layout({ children }) {
  return (
    <>
      <Helmet title="Reflow Labs" defer={false} />
      {children}
      <Footer />
    </>
  );
}
