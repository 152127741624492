import React from 'react';
import ContactForm from './contactForm';

export default function Footer() {
  return (
    <div className="contact">
      <div className="contained">
        <p>If you would like to find out more, just drop us a message.</p>
        <ContactForm />
      </div>
    </div>
  );
}
