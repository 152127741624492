import React from 'react';

import vr from '../assets/vr.svg';
import web from '../assets/web.svg';
import mobile from '../assets/mobile.svg';
import blockchain from '../assets/blockchain.svg';

const lorem =
  'Amet amet fugiat a mollit voluptate in the voluptate exercitation excepteur. Aliquip id Lorem irure ut consectetur id eiusmod id sint ipsum.';

export default function Services() {
  return (
    <div className="contained spaced">
      <div className="services">
        {[
          {
            title: 'Web',
            subTitle: 'Browser',
            description: `
Our bread and butter is creating never before done feats of web-based prowess. 
By utilizing cutting edge frontend tech, we can create solutions that some think is impossible.
            `,
            icon: web,
            style: { transform: 'scale(0.8)' },
          },
          {
            title: 'Mobile',
            subTitle: 'Platform',
            description: `
Desktop, mobile, VR-headset, or whatever the future holds. We can probably program a jellyfish you want us to. 
In any case, we're not limited by typical platform-specific norms.
            `,
            icon: mobile,
          },
          {
            title: 'Blockchain',
            subTitle: 'Era',
            //   description: `
            // We strongly believe that like it or not, blockchain technology is going to be a major feature of human development for the rest of time.
            // That's why we're early adopters in the space and make frontier pushing discoveries.
            //             `,
            description: `
          Technology is shifting and increasingly becoming not just a feature but the cental feature of human development.
          That's why we're habitual early adopters and make frontier pushing discoveries.
                      `,
            icon: blockchain,
            style: { marginTop: '-0.3em' },
          },
          {
            title: 'Virtual Reality',
            subTitle: 'Boundary',
            description: `
We understand the importance of sensation when delivering virtual content.
That's why we endevour to not just use breakthrough technology but combine it with unforgettable visceral experiences. 
            `,
            icon: vr,
            style: { marginTop: '-0.1em' },
          },
        ].map(({ title, description, subTitle, icon, style = {} }) => (
          <div key={title} className="item">
            <div className="icon">
              <img src={icon} alt={title} style={style} />
              <div className="diamond" />
            </div>
            <div className="text">
              <h2>
                <span>Cross-</span>
                {subTitle}
              </h2>
              <p>{description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
