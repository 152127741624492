import React from 'react';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

function ContactInner() {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setState({ ...state, loading: true });
    e.preventDefault();
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': e.target.getAttribute('name'),
          ...state,
        }),
      });
      setState({ ...state, loading: false, success: true });
    } catch (err) {
      setState({ ...state, error: err, loading: false });
    }
  };

  if (state.success) {
    return <span className="success">Message sent. Thank you for contacting us.</span>;
  }
  return (
    <>
      {state.error && <span className="error">{state.error}</span>}
      <form
        disabled={!!state.loading}
        name="contact"
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <input name="bot-field" onChange={handleChange} hidden />
        <input placeholder="Name" required type="text" name="name" onChange={handleChange} />
        <input placeholder="Email" required type="email" name="email" onChange={handleChange} />
        <textarea placeholder="Message" name="message" onChange={handleChange} />
        <button type="submit">Send Message</button>
      </form>
    </>
  );
}

export default function ContactForm() {
  return (
    <div className="contact-form">
      <ContactInner />
    </div>
  );
}
